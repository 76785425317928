import { template as template_98332a2c6a1248d49db4e1e2eee08852 } from "@ember/template-compiler";
const WelcomeHeader = template_98332a2c6a1248d49db4e1e2eee08852(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
