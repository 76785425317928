import { template as template_110ec5f601094375a023005b8bcace7d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_110ec5f601094375a023005b8bcace7d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
